<template>
  <main
    class="work-id"
    :style="{ backgroundColor: `var(--theme-light)`, opacity: 0 }"
  >
    <WorkHero :project="currentProject" />
    <WorkIntro :intro="intro" />

    <ParallaxContainer
      tag="section"
      v-for="section in breakdown"
      :class="section.layout"
      class="work-breakdown"
      :key="section.id"
      :style="section.style"
    >
      <template
        v-if="
          section.layout === 'layout-half' ||
          section.layout === 'layout-quad' ||
          /\blayout-half\b/.test(section.layout) ||
          section.layout === 'layout-full' ||
          section.layout === 'layout-booklet' ||
          section.layout === 'layout-hero'
        "
      >
        <div
          class="work-videos"
          v-if="section.media && section.media.videos"
          :style="section.media.style"
        >
          <DeviceContainer
            v-for="video in section.media.videos"
            :key="video.id"
            :deviceType="video.deviceType"
            :placeholder="video.placeholder"
            :src="video.src"
            :alt="video.alt"
          />
        </div>
        <WorkImages
          v-if="section.media && section.media.images"
          :style="section.media.style"
          :images="section.media.images"
        />
        <WorkText v-if="section.text" :text="section.text" />
      </template>

      <template v-if="section.layout === 'webpage-body'">
        <WorkImages
          class="webpage-images"
          v-if="section.media.images"
          :images="section.media.images"
        />
        <aside>
          <DeviceContainer
            v-for="video in section.media.videos"
            :key="video.id"
            :autoplay="video.autoplay"
            :deviceType="video.deviceType"
            :placeholder="video.placeholder"
            :src="video.src"
            :alt="video.alt"
          />
        </aside>
      </template>

      <template v-if="/\blayout-three-col\b/.test(section.layout)">
        <WorkText v-if="section.text" :text="section.text" />
        <WorkImages v-if="section.media" :images="section.media" />
      </template>

      <template v-if="/\blayout-two-col\b/.test(section.layout)">
        <WorkText v-if="section.text" :text="section.text" />
        <WorkImages
          v-if="section.media.images[0]"
          :images="section.media.images[0]"
        />
        <WorkImages
          v-if="section.media.images[1]"
          :images="section.media.images[1]"
        />
      </template>

      <template v-if="section.layout === 'layout-mobile-deuce'">
        <h3
          class="font-weight-bold work-section-title theme-dark"
          v-if="section.text && section.text.title"
          v-html="section.text.title"
        />
        <div class="col">
          <WorkImages
            v-if="section.media.imges && section.media.images[0]"
            :images="section.media.images[0]"
          />
          <DeviceContainer
            v-if="section.media && section.media.videos"
            :placeholder="section.media.videos[0].placeholder"
            :deviceType="section.media.videos[0].deviceType"
            :src="section.media.videos[0].src"
            class="work-image"
          />
        </div>
        <div class="col">
          <p
            class="work-summary font-size-small theme-dark"
            v-if="section.text && section.text.summary"
            v-html="section.text.summary"
          />
          <WorkImages
            v-if="section.media.images && section.media.images[1]"
            :images="section.media.images[1]"
          />
          <DeviceContainer
            v-if="section.media && section.media.videos"
            :placeholder="section.media.videos[1].placeholder"
            :deviceType="section.media.videos[1].deviceType"
            :src="section.media.videos[1].src"
            class="work-image"
          />
        </div>
      </template>

      <template v-if="section.layout === 'layout-ipad'">
        <ParallaxElement
          class="work-breakdown-bg-color"
          :compensate="false"
          v-if="section.bg"
          style="background-color: var(--theme-highlight)"
        />
        <WorkText v-if="section.text" :text="section.text" />
        <DeviceContainer
          :deviceType="section.media.videos[0].deviceType"
          :autoplay="section.media.videos[0].autoplay"
          class="work-image"
          :placeholder="section.media.videos[0].placeholder"
          :src="section.media.videos[0].src"
          :alt="section.media.videos[0].alt"
          :style="section.media.style"
        />
      </template>
    </ParallaxContainer>

    <section
      class="credits theme-dark"
      v-if="credits"
      :style="{ backgroundColor: `var(--theme-highlight)` }"
    >
      <h4 class="theme-dark">Credits</h4>
      <ul>
        <li v-for="credit in credits" :key="credit.id">
          <span>{{ credit.role }}</span>
          <span>{{ credit.name }}</span>
        </li>
      </ul>
    </section>
    <WorkGrid />
  </main>
</template>

<script>
import { TweenMax } from "gsap";
import { mapGetters } from "vuex";
import randomId from "@/utils/randomId";
import slugify from "@/utils/slugify";
import WorkHero from "@/components/WorkHero.vue";
import WorkImages from "@/components/WorkImages.vue";
import WorkText from "@/components/WorkText.vue";
import WorkGrid from "@/components/WorkGrid.vue";
import WorkIntro from "@/components/WorkIntro.vue";
import DeviceContainer from "@/components/DeviceContainer.vue";
import ParallaxContainer from "@/components/ParallaxContainer";
import ParallaxElement from "@/components/ParallaxElement";
import onLeave from "@/transitions/onLeave";

export default {
  name: "work-id",
  components: {
    WorkHero,
    WorkImages,
    WorkText,
    WorkIntro,
    WorkGrid,
    DeviceContainer,
    ParallaxContainer,
    ParallaxElement,
  },
  beforeRouteLeave(to, from, next) {
    onLeave.call(this, { to, from, next });
  },
  beforeRouteUpdate(to, from, next) {
    onLeave.call(this, { to, from, next });
  },
  computed: {
    ...mapGetters({
      project: "project",
      projects: "projects",
    }),
    currentProject() {
      return this.project(this.$route.params.id);
    },
    currentProjectIndex() {
      return this.projects.findIndex((p) => p.id === this.currentProject.id);
    },
    nextProject() {
      const nextIndex =
        this.currentProjectIndex === this.projects.length - 1
          ? 0
          : this.currentProjectIndex + 1;
      return this.projects[nextIndex];
    },
    prevProject() {
      const prevIndex =
        this.currentProjectIndex === 0
          ? this.projects.length - 1
          : this.currentProjectIndex - 1;
      return this.projects[prevIndex];
    },
    isLeftSide() {
      return this.currentProjectIndex % 3 === 1;
    },
    hero() {
      return this.currentProject.hero;
    },
    intro() {
      return !this.currentProject.intro
        ? []
        : this.currentProject.intro.map((col) => {
            return {
              slug: slugify(col.title),
              id: randomId(),
              ...col,
            };
          });
    },
    breakdown() {
      return this.currentProject.breakdown.map((col) => {
        return {
          id: randomId(),
          ...col,
        };
      });
    },
    credits() {
      if (this.currentProject.credits) {
        return this.currentProject.credits.map((credit) => {
          return {
            id: randomId(),
            ...credit,
          };
        });
      }
    },
  },
  watch: {
    $route(to) {
      this.updateTheme();
    },
  },
  methods: {
    randomId,
    updateTheme() {
      this.$store.dispatch("updateTheme", { ...this.currentProject.color });
      this.$store.commit("SET_CURRENT_PROJECT", this.currentProject);
    },
  },
  mounted() {
    this.updateTheme();
    TweenMax.to(this.$el, 1, {
      autoAlpha: 1,
      x: 0,
      ease: Sine.easeOut,
    });
  },
};
</script>

<style lang="scss">
@use "sass:math";
@import "../styles/mixins";

.theme-dark {
  color: var(--theme-dark);
}

.left-side .work-poster-image {
  align-self: flex-start;
}

/*
Work Breakdown 
.....................................
*/

.work-section-title {
  margin: 0 0 vw(30);
}

.work-breakdown {
  position: relative;
  width: 100%;
}

.work-breakdown-bg-color {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

/*
Layout Half
.....................................
*/

.work-breakdown.layout-half {
  display: flex;
  @include bp(mobile) {
    flex-direction: column-reverse;
  }
  .work-text {
    width: 50%;
    padding: vw(90) vw(var(--gutter));
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include bp(mobile) {
      width: 100%;
      padding: vw(var(--gutter));
    }
  }
  .work-section-title {
    padding: 0;
    position: relative;
    z-index: 1;
  }
  .work-videos,
  .work-images {
    width: 50%;
    @include bp(mobile) {
      width: 100%;
    }
  }
  .work-image,
  .device-container {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
  figcaption.vertical-text {
    width: vw(var(--gutter));
    top: 0;
    display: flex;
    align-items: center;
    @include bp(mobile) {
      width: #{calc(var(--gutted) * 2)};
    }
  }
  .work-summary {
    position: relative;
    z-index: 1;
    @include bp(tablet) {
      width: 100%;
    }
  }

  &:not(.reversed) .has-alt-text {
    padding-left: vw(var(--gutter));
    @include bp(mobile) {
      padding-left: #{calc(var(--gutted) * 2)};
    }
    .vertical-text {
      left: 0;
      right: initial;
    }
  }

  &.reversed {
    flex-direction: row-reverse;
    @include bp(mobile) {
      flex-direction: column-reverse;
    }
    .vertical-text {
      right: 0;
    }
    .has-alt-text {
      padding-right: vw(var(--gutter));
      @include bp(mobile) {
        padding-right: #{calc(var(--gutted) * 2)};
      }
    }
  }
}

/*
Two Col
.....................................
*/

.work-breakdown.layout-two-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-right: vw(var(--gutter));
  @include bp(mobile) {
    grid-template-columns: 1fr;
  }
  .work-text {
    grid-column: 1/3;
    padding: vw(var(--gutter));
    @include bp(mobile) {
      width: 100%;
      grid-column: initial;
      .work-section-title {
        margin: 0;
      }
    }
  }
  .work-section-title {
    padding: 0;
    position: relative;
    z-index: 1;
  }

  .work-image,
  .device-container {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }

  .has-alt-text figcaption.vertical-text {
    width: vw(var(--gutter));
    top: 0;
    display: flex;
    align-items: center;
    @include bp(mobile) {
      width: #{calc(var(--gutted) * 2)};
      &:empty {
        display: none;
      }
    }
  }
  .work-summary {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    width: 50%;
    @include bp(tablet) {
      width: 100%;
    }
  }

  &:not(.reversed) figure.has-alt-text {
    padding-left: vw(var(--gutter));
    @include bp(mobile) {
      display: flex;
      flex-direction: column-reverse;
    }
    .vertical-text {
      right: vw(calc(var(--gutter) * -1));
      left: initial;
      @include bp(mobile) {
        right: initial;
        width: auto;
        position: relative;
        writing-mode: initial;
        padding: vw(var(--gutter)) 0;
        &:empty {
          display: none;
        }
      }
    }
  }
}

/*
Two Col
.....................................
*/

.work-breakdown.layout-three-col {
  .work-text {
    padding: vw(var(--gutter));
  }
  .work-summary {
    width: clamp(375px, 600px, 55%);
  }
  .work-images {
    padding: 0 vw(var(--gutter));
    display: grid;
    grid-gap: vw(90);
    grid-template-columns: 1fr 1fr 1fr;

    .work-image img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
    @include bp(tablet) {
      grid-gap: vw(calc(var(--gutter) / 2));
    }
    @include bp(mobile) {
      grid-template-columns: 1fr;
    }
  }
}

/*
Layout Full
.....................................
*/

.work-breakdown.layout-full {
  display: flex;
  flex-direction: column-reverse;
  .work-text {
    display: flex;
    @include bp(mobile) {
      flex-direction: column;
    }
    .work-section-title {
      padding: vw(var(--gutter));
    }
    .work-summary {
      padding: vw(var(--gutter)) vw(var(--gutter)) vw(var(--gutter))
        vw(calc(var(--gutter) / 2));
      @include bp(mobile) {
        padding: vw(var(--gutter));
      }
    }
    .work-section-title,
    .work-summary {
      width: 50%;
      @include bp(mobile) {
        width: auto;
        margin: 0;
      }
    }
    .work-summary {
      @include bp(mobile) {
        padding-top: 0;
      }
    }
  }
  .work-image {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-of-type) {
      margin-bottom: vw(var(--gutter));
    }
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      display: block;
    }
    video {
      height: 100%;
    }
    @include bp(mobile) {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }
  }
  .work-image.has-alt-text {
    padding: 0 vw(var(--gutter));
  }
  .vertical-text {
    left: 0;
    right: initial;
    width: vw(var(--gutter));
    align-items: center;
    display: flex;
    @include bp(mobile) {
      width: auto;
      position: relative;
      writing-mode: lr;
      top: initial;
      display: block;
      margin-bottom: vw(15);
      align-self: flex-start;
    }
  }
}

/*
Layout Webpage Hero
.....................................
*/

.work-breakdown.layout-hero {
  display: grid;
  position: relative;
  @include bp(tablet) {
    display: flex;
    flex-direction: column-reverse;
  }
  .work-images {
    grid-column: 1/2;
  }
  .work-image {
    width: vw(585);
    position: relative;
    margin-left: vw(var(--gutter));
    z-index: 1;
    @include bp(tablet) {
      margin-bottom: 0;
      width: calc(100% - #{calc(var(--gutted) * 2)});
    }
    div {
      overflow: hidden;
    }
    img {
      width: 100%;
      object-fit: 100% auto;
      object-position: 50% 0;
    }
    figcaption {
      position: absolute;
      top: vw(30);
      right: vw(-60);
      @include bp(mobile) {
        display: none;
      }
    }
  }
  .work-text {
    grid-column: 2/3;
    width: vw(360);
    position: relative;
    z-index: 1;
    @include bp(tablet) {
      width: 100%;
      grid-row: 2/3;
      grid-column: 1/2;
      padding: 0 vw(var(--gutter));
      margin-bottom: vw(60);
      br {
        display: none;
      }
      p {
        column-count: 2;
        column-gap: var(--gutted);
        @include bp(mobile) {
          column-count: 1;
          br {
            display: block;
          }
        }
      }
    }
  }
}

/*
Layout Webpage Body
.....................................
*/

.work-breakdown.webpage-body {
  display: flex;
  position: relative;
  padding-top: 0;
  min-height: 100vh;
  justify-content: space-between;
  @include bp(tablet) {
    flex-direction: column;
  }

  .webpage-images {
    width: vw(585);
    padding-left: vw(var(--gutter));
    @include bp(tablet) {
      width: calc(100% - #{vw(var(--gutter))});
    }
  }

  .vertical-text {
    right: vw(calc(var(--gutter) * -1));
    width: vw(var(--gutter));
    align-items: center;
    display: flex;
  }
  .work-image {
    width: inherit;
    height: auto;
    position: relative;
    z-index: 1;
    margin: 0;
    @include bp(tablet) {
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      display: block;
      @include bp(tablet) {
        width: 100%;
        object-position: 50% 0;
      }
    }
  }
  aside {
    width: 50vw;
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    overflow: hidden;
    z-index: 1;
    .device-container {
      align-self: center;
    }

    @include bp(tablet) {
      grid-column: 1/2;
      position: relative;
      width: 100%;
    }
  }
}

/*
Layout Mobile Deuce
.....................................
*/
.work-breakdown.layout-mobile-deuce {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  padding-left: vw(var(--gutter));
  padding-right: vw(var(--gutter));
  position: relative;
  z-index: 1;
  @include bp(tablet) {
    display: flex;
    flex-direction: column;
  }
  .work-section-title {
    margin-bottom: vw(var(--gutter));
    grid-column: 1/3;
  }
  .col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  .work-summary {
    width: vw(360);
    align-self: flex-start;
    margin-bottom: vw(var(--gutter));
    @include bp(tablet) {
      width: auto;
      margin: vw(var(--gutter)) 0;
      column-count: 2;
      column-gap: vw(var(--gutter));
      br {
        display: none;
      }
    }
    @include bp(tablet) {
      column-count: 1;
      br {
        display: block;
      }
    }
  }
}

/*
Layout iPad
.....................................
*/

.work-breakdown.layout-ipad {
  position: relative;
  .work-breakdown-bg-color {
    bottom: 40%;
  }
  .work-text {
    width: auto;
    padding: vw(calc(var(--gutter) * 0.5)) vw(var(--gutter));
    margin: 0 auto vh(30);
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include bp(mobile) {
      margin-bottom: 15px;
      flex-direction: column;
    }
  }
  .work-text p {
    width: 45%;
    @include bp(tablet) {
      width: 65%;
    }
    @include bp(mobile) {
      width: auto;
    }
  }
  .no-container {
    @include bp(mobile) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    figcaption.vertical-text {
      right: vw(calc(var(--gutter) * -1));
      width: vw(var(--gutter));
      display: flex;
      align-items: center;
      @include bp(mobile) {
        right: initial;
        width: auto;
        position: relative;
        writing-mode: initial;
        top: initial;
        margin-bottom: vw(15);
      }
    }
  }
}

/*
Layout Quad
.....................................
*/

.work-breakdown.layout-quad {
  padding: 0 vw(var(--gutter));
  display: flex;
  flex-direction: column-reverse;
  .work-text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 vw(calc(var(--gutter) / 2));
    margin-bottom: vw(calc(var(--gutter) / 2));
    @include bp(mobile) {
      display: flex;
      flex-direction: column;
    }
    h3 {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    h4 {
      grid-column: 1/2;
      grid-row: 2/3;
    }
    p {
      margin: 0;
      grid-row: 1/3;
      grid-column: 2/3;
      @include bp(mobile) {
        margin-top: vw(15);
      }
    }
  }
  .work-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: vw(calc(var(--gutter) / 2));
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
}

section.credits {
  margin-top: vh(-180);
  padding: vh(180) vw(90) vh(90);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  ul {
    min-width: vw(540);
    padding: 0;
    list-style: none;
    width: 100%;
  }

  ul li {
    padding: vh(15) 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(black, 0.05);
  }
  ul li span:last-of-type {
    margin-left: vw(90);
    width: 60%;
    text-align: right;
  }
}

$x-space: 60;
$y-space: 30;

.vertical-text {
  position: absolute;
  writing-mode: tb;
  top: vw($y-space);
  right: vw($x-space);
  // for images with png and transparencey around
  img[src*="png"] + & {
    top: vh($y-space * 3);
    right: vw(math.div(-$x-space, 2));
  }
}

.work-image.has-alt-text {
  position: relative;
}

.work-image.has-alt-text:not(.device-container),
.work-image.has-alt-text:not(.layout-half.reversed) {
  @include bp(mobile) {
    display: flex;
    flex-direction: column-reverse;
    .vertical-text {
      top: initial;
      right: initial;
      position: relative;
      writing-mode: initial;
      margin-bottom: vw(var(--gutter));
    }
  }
}

#page.work-id + #app-footer {
  color: var(--theme-light);
  background: var(--theme-highlight);
}

#page.work-id + #app-footer .bg {
  transition: all 0.3s ease-in;
  &:before {
    opacity: 0;
  }
}

#page.work-id .work-grid {
  padding-top: vw(var(--gutter));
  padding-bottom: vw(var(--gutter));
  background: var(--theme-highlight);
}
</style>
